<script setup></script>
<template>
	<main>
		<h1 class="title">INTERNATIONAL WOMEN’S DAY CELEBRATION AT HAIER</h1>
		<p>Women have always played an essential role in society, and their contributions have been critical in shaping the world we live in today. However, even in the 21st century, women continue to face various challenges and obstacles in their personal and professional lives. Haier, the leading global home appliance brand, recognizes the role played by women in society and has been at the forefront of empowering them.</p>
		<p>
			<img src="@/assets/images/news2.jpg" alt="" />
		</p>
		<p>On 8th March 2023, in the light of International Women’s Day, Haier organized special activities at the Lahore Head Office to honour women and their achievements. These activities also included the gifts and goodies, that were aimed to recognize the critical role they play every day to take the company to great heights.</p>
		<p>To acknowledge the exceptional women who make significant contributions in their respective fields, Haier’s team also did a cake-cutting ceremony. The celebration was a resounding success, with female employees from various departments and positions being acknowledged for their hard work, and passion. The day’s activities included a range of things, from speeches and presentations to fun activities in the presence of senior management.</p>
		<p>Haier has been a pioneer in promoting gender equality and women’s empowerment has implemented policies and practices to ensure that women have equal opportunities in the workplace. The company has a diverse workforce, with women comprising a significant percentage of its employees. The main focus of celebrating Women’s Day was to embrace diversity and equity, and the values that Haier has always supported to enable its female staff to grow and shine through their skills.</p>
	</main>
</template>
<style scoped>
main {
	width: var(--minWidth);
	margin: 0 auto;
	padding: 0 160px;
}
.title {
	font-size: 30px;
	font-weight: 400;
}
p {
	font-size: 14px;
	margin: 50px 0;
}
p img {
	display: block;
	margin: 0 auto;
}
</style>
